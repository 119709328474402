import React from 'react'
import styles from './index.module.css'

import { Grid } from '@material-ui/core'
import NewsElement from '../newsElement'
import cn from 'classname'
import { useStaticQuery } from 'gatsby'
import get from 'lodash/get'

export default ({ value, index }) => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      allContentfulVideo(limit: 1) {
        nodes {
          id
          slug
          contenuto {
            childMarkdownRemark {
              html
            }
          }
          dataPubblicazione
          titolo
          embed {
            embed
          }
        }
      }
      allContentfulSocialNews(limit: 1) {
        edges {
          node {
            id
            etichetta
            titolo
            url
          }
        }
      }
      allContentfulArticolo(
        sort: { fields: [dataPubblicazione], order: DESC }
        limit: 7
      ) {
        edges {
          node {
            id
            titolo
            slug
            dataPubblicazione(formatString: "MMMM Do, YYYY")
            categorie {
              id
              label
            }
            autore {
              cognome
              nome
              descrizione
            }
            dataPubblicazione(formatString: "MMMM Do, YYYY")
            heroImage {
              fluid(maxWidth: 1200, background: "rgb:000000") {
                ...GatsbyContentfulFluid
              }
            }
            contenuto {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  const video = get(data, 'allContentfulVideo.nodes.0')
  const socialNews = get(data, 'allContentfulSocialNews.edges.0.node')
  const articoli = get(data, 'allContentfulArticolo.edges').map(
    (articolo) => articolo.node
  )

  let news = []
  news.push(...articoli)
  news.splice(1, 0, socialNews)
  news.splice(4, 0, video)
  news = news.filter(a => a.titolo !== "NON ELIMINARE")
  return (
    <div className={styles.container}>
      {value === index && (
        <div className={styles.gridContainer}>
          <Grid container>
            <Grid
              item
              container
              xs={12}
              className={cn(styles.gridItem, styles.gridItemBorder)}
            >
              {news.length > 0 && (
                <Grid item lg={7} className={styles.gridItemBorderLeftColumn}>
                  <NewsElement newsElement={news[0]} isLeftColumn />
                </Grid>
              )}
              {news.length > 1 && (
                <Grid item lg={5}>
                  {news.slice(1, 4).map((newElement) => (
                    <Grid
                      key={`news-${newElement.id}`}
                      className={cn(
                        styles.gridItem,
                        styles.gridItemBorderColumn
                      )}
                      item
                      xs={12}
                    >
                      <NewsElement newsElement={newElement} isRightColumn />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            {news.length > 4 && (
              <Grid
                item
                container
                xs={12}
                className={cn(styles.gridItem, styles.gridItemBorder)}
              >
                {news.slice(4, 7).map((newElement) => (
                  <Grid
                    key={`news-${newElement.id}`}
                    className={cn(styles.gridItem, styles.bottomRowGridItem)}
                    item
                    xs={12}
                    lg={4}
                  >
                    <NewsElement newsElement={newElement} isBottomRow />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  )
}
