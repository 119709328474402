import React from 'react'
import styles from './index.module.css'

import { Grid, Typography } from '@material-ui/core'

export default ({ aboutUs }) => {
  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Grid container justify="center">
          <Grid item lg={2}>
            <Typography variant={'h3'} className={styles.titleTypography}>
              {aboutUs.titolo}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography variant={'h2'}>
              {aboutUs.descrizione.descrizione}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
