import React from 'react'
import styles from './index.module.css'

import { Typography, Grid } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'

export default ({ value, index }) => {
  const data = useStaticQuery(graphql`
    query AnnaouncementQuery {
      allContentfulComunicato {
        edges {
          node {
            titolo
            slug
            contenuto {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  let comunicati = get(data, 'allContentfulComunicato.edges').map(
    (comunicato) => comunicato.node
  )
  comunicati.push(comunicati[0])
  comunicati = comunicati.filter(a => a.titolo !== "NON ELIMINARE")

  return (
    <div className={styles.container}>
      {value === index && (
        <div className={styles.gridContainer}>
          <Grid container justify="center" spacing={2}>
            {comunicati.map((comunicato) => (
              <Grid item xs={12} lg={12}>
                <div
                  className={styles.documentContainer}
                  onClick={() => {
                    window.location.href = `/comunicati/${comunicato.slug}/`
                  }}
                >
                  <Typography variant={'h2'}>{comunicato.titolo}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  )
}
