import React, { Fragment } from 'react'
import styles from './index.module.css'

import { Link, Typography } from '@material-ui/core'
import Img from 'gatsby-image'
import cn from 'classname'

export default ({ newsElement, isLeftColumn, isRightColumn, isBottomRow }) => {
  return (
    <div
      className={
        isLeftColumn
          ? styles.leftColumn
          : isRightColumn
          ? styles.rightColumn
          : isBottomRow
          ? styles.bottomRow
          : null
      }
    >
      {newsElement.heroImage && (
        <Link href={`/articoli/${newsElement.slug}/`}>
          <Img
            className={cn(
              styles.heroImage,
              isRightColumn && styles.heroImageRightColumn,
              isBottomRow && styles.heroImageBottomRows
            )}
            alt=""
            fluid={newsElement.heroImage.fluid}
          />
        </Link>
      )}
      {newsElement.embed ? (
        <div
          className={styles.iframeContainer}
          dangerouslySetInnerHTML={{ __html: newsElement.embed.embed }}
        />
      ) : null}
      {newsElement.etichetta && newsElement.url && (
        <Link className={styles.link} variant="button" href={newsElement.url}>
          {newsElement.etichetta}
        </Link>
      )}
      <div
        className={isRightColumn ? styles.typographyContainerRightColumn : null}
      >
        <Link
          href={
            newsElement.autore
              ? `/articoli/${newsElement.slug}/`
              : newsElement.embed
              ? `/video/${newsElement.slug}/`
              : newsElement.etichetta && newsElement.url
              ? newsElement.url
              : null
          }
        >
          <Typography variant={'h3'} className={styles.title}>
            {newsElement.titolo}
          </Typography>
        </Link>
        {newsElement.autore && (
          <Typography variant={'subtitle1'} className={styles.author}>
            {`${newsElement.autore.nome} ${newsElement.autore.cognome}, ${newsElement.dataPubblicazione}`}
          </Typography>
        )}
        {newsElement.contenuto && newsElement.contenuto.childMarkdownRemark && (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: newsElement.contenuto.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
    </div>
  )
}
