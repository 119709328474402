import React from 'react'
import styles from './index.module.css'
import { Grid, Typography } from '@material-ui/core'
import { useStaticQuery } from 'gatsby'
import get from 'lodash/get'

export default ({}) => {
  const data = useStaticQuery(graphql`
    query WhyUsQuery {
      allContentfulWhyUs {
        edges {
          node {
            descrizione {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  const whyUs = get(data, 'allContentfulWhyUs.edges.0.node')

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Grid container>
          <Grid item container lg={4} className={styles.titleContainer}>
            <Grid item lg={12}>
              <Typography variant={'h1'} className={styles.title1}>
                Perchè
              </Typography>
              <Typography variant={'h1'} className={styles.title2}>
                MRF
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: whyUs.descrizione.childMarkdownRemark.html,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
