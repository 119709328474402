import React from 'react'
import styles from './index.module.css'
import News from '../news'
import Announcement from '../announcement'
import { Grid, AppBar, Tabs, Tab, makeStyles, Link } from '@material-ui/core'

export default ({}) => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const useStyles = makeStyles(() => ({
    appBarRoot: {
      backgroundColor: 'transparent !important',
      color: 'black !important',
      boxShadow: 'none !important',
      borderBottom: '1px solid #D6D6D6 !important',
      flexDirection: 'row !important',
      alignItems: 'flex-end !important',
      marginBottom: '12px !important',
      width: '100% !important',
    },
    tabsRoot: {
      flex: 1,
    },
    tabsIndicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    tabRoot: {
      fontSize: '1.75rem',
      fontFamily: 'Oswald',
      fontWeight: 600,
      textTransform: 'none',
    },
    tabSelected: {
      borderBottom: '4px solid black',
    },
  }))

  const classes = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Grid container>
          <Grid item xs={12}>
            <AppBar
              classes={{
                root: classes.appBarRoot,
              }}
              position="static"
            >
              <Tabs
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
                value={selectedTab}
                onChange={handleChange}
              >
                <Tab
                  label="Primo Piano"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="Comunicati"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              </Tabs>
              <Link
                className={styles.link}
                href={'/lista/articoli/1/'}
                variant="button"
              >
                {'Tutte le news >'}
              </Link>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <News value={selectedTab} index={0} />
            <Announcement value={selectedTab} index={1} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
