import React, { useState } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import AboutUs from '../components/about-us'
import Contents from '../components/contents'
import Survey from '../components/survey'
import WhyMRF from '../components/why-us'
import LoginDialog from '../components/login-dialog'

const RootIndex = (props) => {
  const siteTitle = `${get(
    props,
    'data.site.siteMetadata.title'
  )}`
  const banner = get(props, 'data.allContentfulBanner.edges.0.node')
  const aboutUs = get(props, 'data.contentfulChiSiamo')
  const disabled = true

  return (
    <Layout location={props.location} banner={banner}>
      <Helmet title={siteTitle}>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:url" content="https://www.movimentorivoluzionefiscale.it" />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content="Il Movimento Rivoluzione Fiscale (MRF) è un’associazione culturale e politica nata dal bisogno civico di ristrutturare il sistema fiscale della Repubblica Italiana, attuare i principi della nostra Costituzione, troppo spesso calpestati dai politici italiani, per restituire ai cittadini alta qualità della vita e prospettive di sviluppo." />
        <meta property="og:image" content="https://images.ctfassets.net/mliqjt4giyw6/cwFI082KXOX9EjVefoVLy/03aeab00c0218ccccf02a43fcb04ef6d/Screenshot_2021-03-26_at_10.10.36.png?h=480" />
      </Helmet>
      <AboutUs aboutUs={aboutUs} />
      <Contents />
      {!disabled && <Survey />}
      <WhyMRF />
      <LoginDialog />
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticolo(sort: { fields: [dataPubblicazione], order: DESC }) {
      edges {
        node {
          titolo
          slug
          dataPubblicazione(formatString: "MMMM Do, YYYY")
          categorie {
            id
            label
          }
          heroImage {
            fluid(maxWidth: 1200, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          contenuto {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBanner(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          titolo
          descrizione {
            descrizione
          }
        }
      }
    }
    contentfulChiSiamo {
      titolo
      descrizione {
        descrizione
      }
    }
  }
`
