import React, { useCallback, useMemo } from 'react'
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Grid, makeStyles, Typography,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../input'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../redux-store/reducer'
import cn from 'classname'
import styles from '../form-signin/step-2/index.module.css'

const restURI = process.env.REACT_APP_API_BASE_URL

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Inserire Email valida")
    .required("Email richiesto"),
  password: yup
    .string()
    .min(6, 'Minimo 6 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Password richiesto"),
}).required();

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    maxWidth: '90%'
  },
  input: {
    marginTop: 20
  },
  buttonGreen: {"backgroundColor":"#1D9517 !important","boxShadow":"0px 3px 6px #13670F","borderRadius":"8px !important","width":"200px","height":"60px","color":"#FFF !important","textTransform":"none !important"},
  buttonDisabled: {"backgroundColor":"#B2B2B2 !important","boxShadow":"none !important"}
}))

export default ({}) => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.user.showLoginDialog)

  const { register, handleSubmit, formState, errors, watch, control } = useForm({
    defaultValues: {
      birthDate: null
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const styles = useStyles()

  const isValid = useMemo(() => {
    return formState.isValid
  }, [formState])

  const { isLoading, mutate } = useMutation('postUsers', ({ data }, ) =>
    fetch(`${restURI}/tokens`, {
      method: 'post',
      body: JSON.stringify(data)
    }).then(res => {
        if (res.status !== 200) {
          throw new Error("Credenziali non valide");
        }
        return res.json()
      }
    ), {
    onSuccess: (res) => {
      dispatch(actions.setShowLoginDialog(false));
      dispatch(actions.setIsLogged(true));
      dispatch(actions.setToken(res));
    },
    onError: (e) => {
      alert(e.message);
    }
  });

  const onSubmit = useCallback(
    (_data) => {
      mutate({
        data: _data,
      });
    },
    [mutate]
  );

  const handleClose = useCallback(() => {
    dispatch(actions.setShowLoginDialog(false));
  }, [dispatch])

  return (
    <Dialog open={open} handleClose={handleClose} classes={{
      paper: styles.paper
    }}>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle><Typography variant="h3">Inserisci i tuoi dati</Typography></DialogTitle>
        <DialogContent>
          <Input
            className={styles.input}
            name="email"
            inputRef={register}
            placeholder="Inserisci la tua email"
            fullWidth
            label="Email"
            helperText={errors?.email?.message}
            error={!!errors?.email?.message}
          />
          <Input
            className={styles.input}
            name="password"
            isPassword
            inputRef={register}
            placeholder="Inserisci la tua password"
            fullWidth
            label="Password"
            helperText={errors?.password?.message}
            error={!!errors?.password?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <Button
            classes={{
              root: cn(styles.buttonGreen, (!isValid) ? styles.buttonDisabled : null),
            }}
            disabled={!(isValid) || isLoading}
            variant="contained"
            type={'submit'}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Accedi'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
